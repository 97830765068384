export const Q6ParteFinal = [
    {
        answer: "Partidos",
        id: 7,
    },
    {
        answer: "Finalizaciones",
        id: 8,
    },
    {
        answer: "Duelos",
        id: 9,
    },
]