export const Q6PartePrincipal = [
        {
            answer: "Rondo",
            id: 3,
        },
        {
            answer: "Juego de posesión",
            id: 4,
        },
        {
            answer: "Juegos de posición",
            id: 5,
        },
        {
            answer: "Juegos de situación",
            id: 6,
        },
    ]