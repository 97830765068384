export const Subquestions = [
    [
        {
            answer: "Reinicio",
            value: 1,
            id:"tbg-radio-1",
        },
        {
            answer: "Juego lanzado",
            value: 2,
            id:"tbg-radio-2",
        },
    ],

    [
        {
            answer: "Carril central",
        },
        {
            answer: "Carril exterior",
        },
    ],

    [
        {
            answer: "Carril central",
        },
        {
            answer: "Carril lateral",
        },
    ],

    [
        {
            answer: "Juego organizado",
        },
        {
            answer: "Progresión rápida",
        },
    ],

    [
        {
            answer: "Activo",
        },
        {
            answer: "Reactivo",
        },
    ],

    [
        {
            answer: "Carril central",
        },
        {
            answer: "Carriles laterales",
        },
    ],

    [
        {
            answer: "Repliegue",
        },
        {
            answer: "Presión",
        },
    ],
]
